import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import directive from './directive' // directive
import ZmTreeOrg from 'zm-tree-org'
import "zm-tree-org/lib/zm-tree-org.css"

import Element from "element-ui"
import "element-ui/lib/theme-chalk/index.css"

import axios from './axios'
import global from './globalFun'

// 分页组件
import Pagination from "@/components/Pagination";
// 自定义表格工具组件
import RightToolbar from "@/components/RightToolbar"
// 富文本组件
import Editor from "@/components/Editor"
// 文件上传组件
import FileUpload from "@/components/FileUpload"
// 图片上传组件
import ImageUpload from "@/components/ImageUpload"

Vue.prototype.$axios = axios //
Vue.config.productionTip = false

// require("./mock.js")
// 全局组件挂载
Vue.component('Pagination', Pagination)
Vue.component('RightToolbar', RightToolbar)
Vue.component('Editor', Editor)
Vue.component('FileUpload', FileUpload)
Vue.component('ImageUpload', ImageUpload)

// require("./mock.js")
Vue.use(directive)
Vue.use(Element)
Vue.use(ZmTreeOrg)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
